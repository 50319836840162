<template>
  <nav class="navbar navbar-expand-lg blur border-radius-lg top-0 z-index-3 mt-4 py-2 start-0 end-0">
    <div class="container-fluid">
      <a class="navbar-brand font-weight-bolder ms-lg-0 ms-3" href="/">
        Annuaire Santé
      </a>
      <button type="button"
              class="navbar-toggler shadow-none ms-2"
              data-bs-toggle="collapse"
              data-bs-target="#navigation"
              aria-controls="navigation"
              aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-bar bar1"></span>
        <span class="navbar-toggler-bar bar2"></span>
        <span class="navbar-toggler-bar bar3"></span>
      </button>
      <div class="collapse navbar-collapse" id="navigation">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <a class="nav-link d-flex align-items-center me-2" :href="appLoginPath">
              {{  $t('topmenu.signin') }}
            </a>
          </li>
          <li class="nav-item me-2">
            <a @click="logout" class="nav-link">
              {{  $t('topmenu.language') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      },
      appLoginPath() {
        return process.env.VUE_APP_FRONT_BASE_URL + '/login'
      }
    },
    data () {
      return {
        activeNotifications: false
      }
    },
    methods: {
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
</script>