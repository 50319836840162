<template>
    <div class="card card-plain">
        <div class="card-header pb-0 text-start">
            <h4 class="font-weight-bolder">{{  $t('credentials.title') }}</h4>
        </div>
        <div class="card-body">
            <form role="form">
                <div class="mb-3">
                    <div class="col-md-12">
                        <label>{{  $t('credentials.email') }}</label>
                        <input type="email"
                                class="form-control form-control-lg"
                                label="Email"
                                v-bind:placeholder="$t('placeholder.email')"
                                v-model="user.email"
                                aria-label="Email" />
                    </div>
                </div>
                <div class="mb-3">
                    <div class="col-md-12">
                        <label>{{  $t('credentials.username') }}</label>
                        <input type="text"
                                class="form-control form-control-lg"
                                label="Username"
                                v-bind:placeholder="$t('placeholder.username')"
                                v-model="user.username"
                                aria-label="$t('placeholder.username')" />
                    </div>
                </div>
                <div class="mb-3">
                    <div class="col-md-12">
                        <label>{{  $t('credentials.phone') }}</label>
                        <input type="phone"
                                class="form-control form-control-lg"
                                label="Phone"
                                v-bind:placeholder="$t('placeholder.phone')"
                                v-model="user.phone"
                                aria-label="$t('placeholder.phone')" />
                    </div>
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn-lg btn-primary w-100 mt-4 mb-0" @click.prevent="mysignup" >
                        {{  $t('credentials.register') }}
                    </button>
                </div>
            </form>
        </div>
        <div class="card-footer text-center pt-0 px-lg-2 px-1">
            <p class="mb-4 text-sm mx-auto">
                {{  $t('credentials.have-account') }}
                <a href="http://app.cpts-paris15.fr/login" class="text-primary text-gradient font-weight-bold">{{  $t('credentials.login') }}</a>
            </p>
            <p class="mb-4 text-sm mx-auto" :innerHTML=this.policy></p>
        </div>
    </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores/auth.store'
import { useNotificationStore } from '@/stores/notification.store'

export default {
    data() {
        return {
            policy: ''
        }
    },
    setup() {
        const store = useAuthStore()
        const { user } = storeToRefs(store)
        const { signup } = store

        user.attachedTo = 'CPTS 15'
        
        return { user, signup }
    },
    mounted() {
        let policy_link = '<a href="/policy.html" target="_blank"><b>{0}</b></a>'.replace('{0}', this.$t('credentials.policy-core'))
        this.policy = this.$t('credentials.policy', { policy_link: policy_link })
    },
    methods: {
        async mysignup () {
            useNotificationStore().reset()
            await this.signup(this.user)
                .then(() => {
                    this.$router.push("/check-email")
                })
                .catch(error => {
                    useNotificationStore().setError(error)
                })
        }
    }
}
</script>