<template>
    <div v-if="show" class="row mt-4">
        <div class="col-12">
            <div v-if="errors && errors.length > 0" class="row">
                <div v-for="(error, index) in errors" :key="index" class="alert alert-danger">
                    <span>{{ error.message }}</span>
                </div>
            </div>
            <div v-if="messages && messages.length > 0" class="row">
                <div v-for="(message, index) in messages" :key="index" class="alert alert-success">
                    <span>{{ message }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useNotificationStore } from '@/stores/notification.store'

export default {
    name: 'AlertContent',
    setup() {
        const { errors, messages } = storeToRefs(useNotificationStore())
        return { errors, messages }
    },
    computed: {
        show () {
            return this.errors.length > 0 || this.messages.length > 0
        }
    },
}
</script>