// Import Bootstrap 5.2.3
//import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// Import farmed3 css template
import '@/assets/scss/farmed3/index.scss'

export default {
    install() {
    }
}
