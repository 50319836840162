import { createApp }  from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

// i18n internationalization
import i18n from './helpers/i18n.js'

// Global directives
import GlobalDirectives from './helpers/globalDirectives'

// Bootstrap & template css components
import Css from './helpers/css'

// Global components used across the app
//import NotificationPlugin from '@/plugins/NotificationPlugin'

const pinia = createPinia()

const app = createApp(App)
app.config.devtools=false
app
    .use(router)
    .use(pinia)
    .use(i18n)
    .use(GlobalDirectives)
    .use(Css)

    .mount('#app')
