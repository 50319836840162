import { defineStore } from 'pinia'
import AuthService from '@/services/auth.service'

const userInitialState = {
    email: '',
    //password: '',
    username: '',
    phone: '',
    attachedTo: ''
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: userInitialState,
  }),
  getters: {
    getUser: (state) => { return state.user } 
  },
  actions: {
    async signup(user) {

      await AuthService.signup(user)
        .then(response => {
          this.user = response;
        })
        .catch(error => {
          return Promise.reject(error)
        })

      return Promise.resolve(this.user)
    },
  }
})
