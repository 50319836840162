<template>
    <div class="card card-plain">
        <div class="card-header pb-0 text-start">
            <h4 class="font-weight-bolder">{{  $t('confirmation.title') }}</h4>
        </div>
        <div class="card-body">
            <form role="form">
                <div class="mb-3">
                    <div class="col-md-12">
                        <label>{{  $t('confirmation.credential') }}</label>
                        <input type="text"
                                class="form-control form-control-lg"
                                label="Credential"
                                :disabled="true"
                                v-bind:placeholder="$t('placeholder.username')"
                                v-model="user.username"
                                aria-label="Credential" />
                    </div>
                </div>
                <div class="mb-3">
                    <div class="col-md-12">
                        <label for="roles">{{  $t('confirmation.role') }}</label>
                        <select class="form-control" id="roles" v-model="user.role">
                            <option value="PRACTITIONER" default>{{  $t('roles.practitioner') }}</option>
                            <option value="STRUCTURE">{{  $t('roles.structure') }}</option>
                            <option value="MEMBER">{{  $t('roles.member') }}</option>
                        </select>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="col-md-12">
                        <label>{{  $t('confirmation.password') }}</label>
                        <input type="password"
                                class="form-control form-control-lg"
                                label="Password"
                                autocomplete="on"
                                v-bind:placeholder="$t('placeholder.password')"
                                v-model="user.password"
                                aria-label="Password" />
                    </div>
                </div>
                <div class="mb-3">
                    <div class="col-md-12">
                        <label>{{  $t('confirmation.confirmPassword') }}</label>
                        <input type="password"
                                class="form-control form-control-lg"
                                label="ConfirmPassword"
                                v-bind:placeholder="$t('placeholder.confirmPassword')"
                                v-model="user.confirmPassword"
                                aria-label="Password" />
                    </div>
                </div>
                <div class="mb-3">
                    <div class="col-md-12">
                        <input type="text"
                                hidden
                                v-model="user.token" />
                    </div>
                </div>
                <div class="text-center">
                    <button type="submit" class="btn btn-lg btn-primary w-100 mt-4 mb-0" @click.prevent="myregister" >
                        {{  $t('confirmation.validate') }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useConfirmationStore } from '@/stores/confirmation.store'
import { useNotificationStore } from '@/stores/notification.store'

export default {
    setup() {
        const route = useRoute()

        const store = useConfirmationStore()
        const { user } = storeToRefs(store)
        const { register } = store

        const token = route.query.token
        store.fetchByToken(token)

        return { user, register }
    },
    methods: {
        async myregister () {
            useNotificationStore().reset()
            await this.register(this.user)
                .then(() => {
                    this.$router.push("/signup-end")
                })
                .catch((error) => {
                    useNotificationStore().setError(error)
                })
        }
    }
}
</script>