import api from "./api";

class AuthService {

    signup(user) {
        return api.post('/auth/signup', {
            username: user.username,
            attachedTo: user.attachedTo,
            email: user.email,
            password: user.password,
            phone: user.phone
        })
    }

    getUserByToken(token) {
        return api.get('/auth/enrolled-user/' + token)
    }

    register(user) {
        return api.put('/auth/register', {
            token: user.confirmationToken,
            role: user.role,
            credential: user.username,
            password: user.password,
            confirmPassword: user.confirmPassword
        })
    }

    getConfirmationLink(email) {
        return api.get('/auth/confirmation-link', email, {})
    }
}

export default new AuthService()