<template>
    <div class="card card-plain">
        <div class="card-header pb-0 text-left">
            <h4 class="font-weight-bolder">{{  $t('organization.title') }}</h4>
            <p class="mb-0">{{  $t('organization.attachment') }}</p>
        </div>
        <div class="card-body">
            <div class="row">
                <a href="/signup/credentials?cpts=cpts-15">
                    <button class="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0">CPTS 15</button>
                </a>
            </div>
        </div>
    </div>
</template>
